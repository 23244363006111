import axios from "axios";
import { linkurl } from "../linkurl";
import { toast } from "react-toastify";


const API_URL = linkurl;

// Get All Products
const booking = async (booking) => {

  const res = await axios.post(`${API_URL}/rooms/booking-request`, booking);
  console.log("dsf")
  if (res.data.status === 200) {
 ;
    toast.success(res.data.message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    });
    return res.data.message;
  }
};

const bookingService = {
  booking,
};
export default bookingService;
