import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import servicesServices from "./servicesService";

const initialState = {
  services: [],
  imgUrl: "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get All Services
export const services = createAsyncThunk("services", async (thunkAPI) => {
  try {
    return await servicesServices.services();
  } catch (err) {
    const message =
      (err.response && err.response.data && err.response.data.message) ||
      err.message ||
      err.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const servicesSlice = createSlice({
  name: "services",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(services.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(services.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.services = action.payload.services;
        state.imgUrl = action.payload.image_url;
      })
      .addCase(services.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.services = null;
      });
  },
});

export const { reset } = servicesSlice.actions;
export default servicesSlice.reducer;
