import { configureStore } from "@reduxjs/toolkit";

import aboutReducer, { about } from "./about/aboutSlice";
import faqReducer, { faq } from "./faqs/faqSlice";
import servicesReducer, { services } from "./services/servicesSlice";
import settingReducer, { siteSetting } from "./settings/settingSlice";
import sliderReducer, { sliders } from "./home-slider/sliderSlice";
import packagesReducer, { packages } from "./packages/packageSlice";
import blogsReducer, { blogs } from "./blogs/blogSlice";
import roomReducer, { rooms } from "./rooms/roomSlice";
import bookingReducer from "./room-booking/bookingSlice";
import teamsReducer, { teams } from "./our-teams/teamSlice";
import termsReducer, { terms } from "./terms/termsSlice";

import bookingSiteReducer, { agencies } from "./booking-sites/bookingSiteSlice";
import paymentReducer, {payments} from "./payment-card/paymentSlice";
import galleryReducer, {galleries} from "./gallery/gallerySlice";

export const store = configureStore({
  reducer: {
    about: aboutReducer,
    blogs: blogsReducer,
    blog: blogsReducer,
    booking: bookingReducer,
    faq: faqReducer,
    packages: packagesReducer,
    package: packagesReducer,
    services: servicesReducer,
    setting: settingReducer,
    sliders: sliderReducer,
    rooms: roomReducer,
    room: roomReducer,
    teams: teamsReducer,
    agencies: bookingSiteReducer,
    payments: paymentReducer,
    galleries: galleryReducer,
    terms: termsReducer,


  },
});

store.dispatch(about());
store.dispatch(blogs());
store.dispatch(faq());
store.dispatch(packages());
store.dispatch(services());
store.dispatch(siteSetting());
store.dispatch(sliders());
store.dispatch(rooms());
store.dispatch(teams());
store.dispatch(agencies());
store.dispatch(payments());
store.dispatch(galleries());
store.dispatch(terms());

