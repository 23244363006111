import React from "react";

const Location = ({ setting }) => {
  let map = setting && setting.map ? setting.map : "";
  return (
    <div className="row g-4 mb-3">
      <div className="col-12">
        <iframe
          className="position-relative rounded w-100 h-100"
          src={map}
          title="map"
          style={{ minHeight: "367px", border: "0" }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default Location;
