import axios from "axios";
import { linkurl } from "../linkurl";

const API_URL = linkurl;

// Get All Products
const setting = async () => {
  const res = await axios.get(`${API_URL}/settings`);
  if (res.data.status === 200) {
    return res.data;
  }
};

const settingServices = {
  setting,
};
export default settingServices;
