import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

const Header = () => {

  useEffect(() => {
    const currentUrl = window.location.pathname;
    // console.log(currentUrlz)

    // Get the current page's URL
    // const currentUrl = window.location.href;
    // Get all the navigation links
    const navLinks = document.querySelectorAll('.nav-link');

    // Loop through the navigation links and add the "active" class to the link with a matching href
    navLinks.forEach(link => {

      if (link.getAttribute('href') === currentUrl) {
        link.classList.add('active');
      }
    });

   
  },[])

  
  return (
    <nav className="navbar navbar-expand-md navbar-light ">
      <div className="container-fluid">
        <a className="d-block d-sm-block d-md-block d-md-none navbar-brand " href="/">
        <img src="/assets/logo/logo.png" alt="logo" width="155px" />
        </a>
             {/* <div className="nav-item ms-4 d-none d-lg-block">
              <a className="nav-link btn btn-success text-white " style={{ marginTop: "15px","padding": "7px"}} href="reservation">Reservation</a>
            </div> */}
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <a className="nav-link" aria-current="page" href="/">Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/about">About</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/amenities">Amenities</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/rooms">Accommodations</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/packages">Packages</a>
            </li>
            {/* <li className="nav-item">
              <a className="nav-link" href="/blogs">Blogs</a>
            </li> */}
            <li className="nav-item">
              <a className="nav-link" href="/contact">Contact</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="/gallery">Gallery</a>
            </li>
            <li className="nav-item ">
              <a className="nav-link btn btn-success text-white cos" style={{ marginTop: "15px","padding": "7px"}} href="reservation">Reservation</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
