import React, { useEffect } from 'react'
import { Fancybox as NativeFancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";
import "@fancyapps/ui/dist/fancybox/fancybox.css";

const Fancy = (props) => {
    const delegate = props.delegate || "[data-fancybox]";

    useEffect(() => {
        const opts = props.options || {};
        NativeFancybox.bind(delegate, opts);
        return () => {
            NativeFancybox.destroy();
        };
    }, []);

    return (
        <>{props.children}</>
    )
}

export default Fancy