import React from "react";
import { useSelector } from "react-redux";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import './HomeBanners.css'


const HomeBanners = () => {
  const { banners, imgUrl } = useSelector((state) => state.sliders);
  const settings = {
    infinite: true,
    autoplay:true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
     responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
         
        }
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  }


  let sliders =
    banners &&
    banners.map((banner, idx) => (
      <div className={`carousel-item ${idx === 0 ? "active" : ""}`} key={idx}>
        {banner && banner.image ? (
          <img
            className="w-100"
            src={`${imgUrl}/${banner.image}`}
            alt={banner.title}
          />
        ) : (
          ""
        )}
      </div>
    ));

  return (
    <div className="container-fluid p-0 mb-5">
      <div
      >
        <Slider {...settings}>{sliders}</Slider>
 
      </div>
    </div>
  );
};

export default HomeBanners;
