import axios from "axios";
import { linkurl } from "../linkurl";

const API_URL = linkurl;

// Get All Teams
const galleries = async () => {
  const res = await axios.get(`${API_URL}/pictures`);
  if (res.data.status === 200) {
    return res.data;
  }
};

const galleryServices = {
  galleries,
};
export default galleryServices;
