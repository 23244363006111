import React from "react";
import { useSelector } from "react-redux";

const Faq = () => {
  const { faq } = useSelector((state) => state.faq);
  return (
    <div className="container">
      <div className="faq-header text-center mt-3 mb-5">
        <h1>Frequently Ask Questions</h1>
      </div>
      <div
        className="faqs"
        dangerouslySetInnerHTML={{ __html: faq && faq.faqs ? faq.faqs : "" }}
      ></div>
    </div>
  );
};

export default Faq;
