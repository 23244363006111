import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import settingServices from "./settingServices";

const initialState = {
  setting: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get All setting
export const siteSetting = createAsyncThunk("setting", async (thunkAPI) => {
  try {
    return await settingServices.setting();
  } catch (err) {
    const message =
      (err.response && err.response.data && err.response.data.message) ||
      err.message ||
      err.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const settingSlice = createSlice({
  name: "setting",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(siteSetting.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(siteSetting.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.setting = action.payload.setting;
      })
      .addCase(siteSetting.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.setting = null;
      });
  },
});

export const { reset } = settingSlice.actions;
export default settingSlice.reducer;
