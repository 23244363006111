import axios from "axios";
import { linkurl } from "../linkurl";

const API_URL = linkurl;

// Get All Products
const faq = async () => {
  const res = await axios.get(`${API_URL}/faqs`);
  if (res.data.status === 200) {
    return res.data;
  }
};

const faqServices = {
  faq,
};
export default faqServices;
