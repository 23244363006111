import React from "react";
import { useSelector } from "react-redux";

const About = () => {
  const { about } = useSelector((state) => state.about);

  return (
    <div className="container-xxl ">
      <div className="container ">
        <div className="row g-5 align-items-center">
          <div className="col-lg-6">
            {/* <h6 className="section-title text-start text-primary">
              About Us
            </h6> */}
            <h1 className="">
             <span className="wl"> Welcome {""}</span>
             
            </h1>
            <h1 className="">
                {about && about.title ? about.title : ""}
              </h1>
            <div
              className="mb-4"
              dangerouslySetInnerHTML={{
                __html: `${
                  about && about.description
                    ? about.description.substr(0, 500)+"...."
                    : ""
                }`,
              }}
            ></div>
            <div className="row g-3 pb-4">
              {about && about.rooms ? (
                <div className="col-sm-4 wow fadeIn" data-wow-delay="0.1s">
                  <div className="border rounded p-1">
                    <div className="border rounded text-center p-4">
                      <i className="fa fa-hotel fa-2x text-primary mb-2"></i>
                      <h2 className="mb-1" data-toggle="counter-up">
                        {about.rooms}
                      </h2>
                      <p className="mb-0">Rooms</p>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {about && about.staff ? (
                <div className="col-sm-4 wow fadeIn" data-wow-delay="0.3s">
                  <div className="border rounded p-1">
                    <div className="border rounded text-center p-4">
                      <i className="fa fa-users-cog fa-2x text-primary mb-2"></i>
                      <h2 className="mb-1" data-toggle="counter-up">
                        {about.staff}
                      </h2>
                      <p className="mb-0">Staffs</p>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}

              {about && about.clients ? (
                <div className="col-sm-4 wow fadeIn" data-wow-delay="0.5s">
                  <div className="border rounded p-1">
                    <div className="border rounded text-center p-4">
                      <i className="fa fa-users fa-2x text-primary mb-2"></i>
                      <h2 className="mb-1" data-toggle="counter-up">
                        {about.clients}
                      </h2>
                      <p className="mb-0">Clients</p>
                    </div>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
            <a className="theme-btn btn-style-two" href="/about-chitwan">
            Read More
          </a>
          </div>
          <div className="col-lg-6">
            <div className="row g-3">
              <div className="col-6 text-end">
                <img
                  className="img-fluid rounded w-75 wow zoomIn"
                  data-wow-delay="0.1s"
                  src="assets/img/about-1.jpg"
                  style={{ marginTop: "25%" }}
                  alt="abt"
                />
              </div>
              <div className="col-6 text-start">
                <img
                  className="img-fluid rounded w-100 wow zoomIn"
                  data-wow-delay="0.3s"
                  src="assets/img/about-2.jpg"
                  alt="abt"
                />
              </div>
              <div className="col-6 text-end">
                <img
                  className="img-fluid rounded w-60 wow zoomIn"
                  data-wow-delay="0.5s"
                  src="assets/img/about-3.jpg"
                  alt="abt"
                />
              </div>
              <div className="col-6 text-start">
                <img
                  className="img-fluid rounded w-75 wow zoomIn"
                  data-wow-delay="0.7s"
                  src="assets/img/about-4.jpg"
                  alt="abt"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
