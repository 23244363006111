import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import RecentBlogs from "./RecentBlogs";

const Blogs = () => {
  const { blogs, imgUrl } = useSelector((state) => state.blogs);

  let BlogList = blogs.map((blog, idx) => (
    <div key={idx}>
      <h4>{blog.title}</h4>
      <div className="card mb-3">
        {blog && blog.image ? (
          <img
            src={`${imgUrl}/${blog.image}`}
            className="card-img-top"
            alt={blog.title}
          />
        ) : (
          ""
        )}

        <div className="card-body">
          <h5 className="card-title">{blog.title}</h5>
          <p className="card-text">
            <small className="text-muted">
              Post By: {blog.author},{" "}
              {blog && blog.created_at
                ? moment(blog.created_at).utc().format("MM Do YY")
                : ""}
            </small>
          </p>
          <p className="card-text">{blog.short_description}</p>
          <a href={`/blog/${blog.slug}`} className="stretched-link">
            Continue Reading ...
          </a>
          <p className="card-text">
            <small className="text-muted">
              Last updated{" "}
              {blog && blog.created_at
                ? moment(blog.created_at).utc().startOf("hour").fromNow()
                : ""}
            </small>
          </p>
        </div>
      </div>
    </div>
  ));

  return (
    <div>
      <div
        className="container-fluid page-header mb-5 p-0"
        style={{
          backgroundImage: "url(assets/img/carousel-2.jpg)",
          minHeight: "300px",
        }}
      >
        <div className="container-fluid page-header-inner py-5 d-flex align-items-end">
          <div className="container text-center pb-5 mt-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Our Blogs
            </h1>
          </div>
        </div>
      </div>

      <div className="container-xxl">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title text-center text-primary">
              OUR Blogs
            </h6>
            <h1 className="mb-5">
              {/* <span className="text-primary">Contact</span> */}
              News & Events
            </h1>
          </div>

          {/* Main Blogs Content */}
          {/* Main Content */}
          <div className="row g-4">
            <div className="col-md-9">{BlogList}</div>
            <div className="col-md-3">
              <h4>Recent Posts</h4>

              <RecentBlogs />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Blogs;
