import axios from "axios";
import { linkurl } from "../linkurl";

const API_URL = linkurl;

// Get All Products
const about = (async () => {
  const res = await axios.get(`${API_URL}/about`);
  console.log(res)
  if (res.data.status === 200) {
    return res.data;
  }
});

const aboutServices = {
  about,
};
export default aboutServices;
