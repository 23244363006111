import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import galleryServices from "./galleryServices";

const initialState = {
  galleries: [],
  imgUrl: "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get All Teams
export const galleries = createAsyncThunk("galleries", async (thunkAPI) => {
  try {
    return await galleryServices.galleries();
  } catch (err) {
    const message =
      (err.response && err.response.data && err.response.data.message) ||
      err.message ||
      err.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const gallerySlice = createSlice({
  name: "galleries",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(galleries.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(galleries.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.galleries = action.payload.pictures;
        state.imgUrl = action.payload.image_url;
      })
      .addCase(galleries.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.galleries = null;
      });
  },
});

export const { reset } = gallerySlice.actions;
export default gallerySlice.reducer;
