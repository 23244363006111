import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { singleBlog } from "../../redux/blogs/blogSlice";
import RecentBlogs from "./RecentBlogs";

const SingleBlog = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const { blog, imgUrl } = useSelector((state) => state.blog);

  useEffect(() => {
    dispatch(singleBlog(slug));
  }, [dispatch, slug]);

  let singleBlogData =
    blog && blog.slug ? (
      <div>
        {/* <h4>{blog.title}</h4> */}
        <div className="card mb-3">
          {blog && blog.image ? (
            <img
              src={`${imgUrl}/${blog.image}`}
              className="card-img-top"
              alt={blog.title}
            />
          ) : (
            ""
          )}

          <div className="card-body">
            <h5 className="card-title">{blog.title}</h5>
            <p className="card-text">
              <small className="text-muted">
                Post By: {blog.author},{" "}
                {blog && blog.created_at
                  ? moment(blog.created_at).utc().format("MM Do YY")
                  : ""}
              </small>
            </p>
            <div
              className="card-text"
              dangerouslySetInnerHTML={{ __html: blog.description }}
            ></div>
            <p className="card-text">
              <small className="text-muted">
                Last updated{" "}
                {blog && blog.created_at
                  ? moment(blog.created_at).utc().startOf("hour").fromNow()
                  : ""}
              </small>
            </p>
          </div>
        </div>
      </div>
    ) : (
      ""
    );

  return (
    <div>
      <div className="container-xxl">
        <div className="container pt-4">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title text-center text-primary">
            News & Events
            </h6>
            <h1 className="mb-5">
              {/* <span & Eventsspan className="text-primary">Contact</span> */}
              {blog && blog.title}
            </h1>
          </div>

          {/* Main Blogs Content */}
          {/* Main Content */}
          <div className="row g-4">
            <div className="col-md-9">{singleBlogData}</div>
            <div className="col-md-3">
              <h4>Recent Posts</h4>
              <RecentBlogs />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleBlog;
