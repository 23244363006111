import React from "react";
import Teams from "../home-page/home-components/Teams";
import { useSelector } from "react-redux";
import Whyus from "./Whyus";

const About = () => {
  const { about, whyus, imgUrl } = useSelector((state) => state.about);
  const { board_members, staffs } = useSelector((state) => state.teams);

  return (
    <>
      <div className="container-xxl py-5">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h6 className="section-title text-center text-primary">
            About Us
          </h6>
          <h1 className="mb-3">
            <span className="text-primary">Welcome to</span>{" "}
            {about && about.resort ? about.resort : ""}
          </h1>
        </div>

        <div className="container">
          <div className="row g-5 align-items-center">
            <div className="col-lg-6">
              <h6 className="section-title text-start text-primary">
              {about && about.resort ? about.resort : ""}
              </h6>

              <div
                className="mb-4"
                dangerouslySetInnerHTML={{
                  __html: `${
                    about && about.resort_description ? about.resort_description : ""
                  }`,
                }}
              ></div>

              <div className="row g-3 pb-4">
                {about && about.rooms ? (
                  <div className="col-sm-4 wow fadeIn" data-wow-delay="0.1s">
                    <div className="border rounded p-1">
                      <div className="border rounded text-center p-4">
                        <i className="fa fa-hotel fa-2x text-primary mb-2"></i>
                        <h2 className="mb-1" data-toggle="counter-up">
                          {about.rooms}
                        </h2>
                        <p className="mb-0">Rooms</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {about && about.staff ? (
                  <div className="col-sm-4 wow fadeIn" data-wow-delay="0.3s">
                    <div className="border rounded p-1">
                      <div className="border rounded text-center p-4">
                        <i className="fa fa-users-cog fa-2x text-primary mb-2"></i>
                        <h2 className="mb-1" data-toggle="counter-up">
                          {about.staff}
                        </h2>
                        <p className="mb-0">Staffs</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}

                {about && about.clients ? (
                  <div className="col-sm-4 wow fadeIn" data-wow-delay="0.5s">
                    <div className="border rounded p-1">
                      <div className="border rounded text-center p-4">
                        <i className="fa fa-users fa-2x text-primary mb-2"></i>
                        <h2 className="mb-1" data-toggle="counter-up">
                          {about.clients}
                        </h2>
                        <p className="mb-0">Clients</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
                  {/* <a className="btn btn-primary py-3 px-5 mt-2" href="#">
                    Explore More
                  </a> */}
                </div>
                {/* <div className="col-lg-6">
                  <div className="row g-3">
                    <div className="col-6 text-end">
                      <img
                        className="img-fluid rounded w-75 wow zoomIn"
                        data-wow-delay="0.1s"
                        src="assets/img/about-1.jpg"
                        style={{ marginTop: "25%" }}
                        alt="abt-img"
                      />
                    </div>
                    <div className="col-6 text-start">
                      <img
                        className="img-fluid rounded w-100 wow zoomIn"
                        data-wow-delay="0.3s"
                        src="assets/img/about-2.jpg"
                        alt="abt"
                      />
                    </div>
                    <div className="col-6 text-end">
                      <img
                        className="img-fluid rounded w-50 wow zoomIn"
                        data-wow-delay="0.5s"
                        src="assets/img/about-3.jpg"
                        alt="abt"
                      />
                    </div>
                    <div className="col-6 text-start">
                      <img
                        className="img-fluid rounded w-75 wow zoomIn"
                        data-wow-delay="0.7s"
                        src="assets/img/about-4.jpg"
                        alt="abt"
                      />
                    </div>
                  </div>
                </div> */}

                <div className="col-lg-6">
                  <div className="row">
                    <div className="col-lg-4 col-md-4 col-sm-4 mb-4 mb-lg-0">
                      <img
                        src="assets/aboutUnique/11.png"
                        className="w-100 shadow-1-strong rounded mb-4"
                        alt="Waves at Sea"
                      />

                      <img
                        src="assets/aboutUnique/16.png"
                        className="w-100 shadow-1-strong rounded mb-4"
                        alt="Wintry Mountain Landscape"
                      />
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-4 mb-4 mb-lg-0">
                      <img
                        src="assets/aboutUnique/14.png"
                        className="w-100 shadow-1-strong rounded mb-4"
                        alt="Mountains in the Clouds"
                      />

                      <img
                        src="assets/aboutUnique/13.png"
                        className="w-100 shadow-1-strong rounded mb-4"
                        alt="Boat on Calm Water"
                      />
                    </div>

                    <div className="col-lg-4 col-md-4 col-sm-4 mb-4 mb-lg-0">

                          <img
                        src="assets/aboutUnique/12.png"
                        className="w-100 shadow-1-strong rounded mb-4"
                        alt="Waves at Sea"
                      />

                      <img
                        src="assets/aboutUnique/15.png"
                        className="w-100 shadow-1-strong rounded mb-4"
                        alt="Yosemite National Park"
                      />
                    </div>
                  </div>
               </div>
          </div>
        </div>
      </div>
      <Whyus whyus={whyus} imgUrl={imgUrl}/>
      <Teams board_members={board_members} staffs={staffs} imgUrl={imgUrl} />
    </>
  );
};

export default About;
