import React from "react";
import { useSelector } from "react-redux";

const Terms = () => {
  const { terms } = useSelector((state) => state.terms);
//   console.log(terms)
  return (
    <div className="container">
      <div className="terms-header text-center mt-3 mb-5">
        <h1>{terms.title}</h1>
      </div>
      <div
        className="terms"
        dangerouslySetInnerHTML={{ __html: terms && terms.description ? terms.description : "" }}
      ></div>
    </div>
  );
};

export default Terms;
