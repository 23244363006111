import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Service = () => {
  const { services, imgUrl } = useSelector((state) => state.services);

  let serviceData =
    services &&
    services.slice(0, 1).map((service, idx) => (
      <div className="service-block-three" key={idx}>
        <div className="row">
          <div
            className="col-lg-7 col-md-7 col-sm-12 image-column wow fadeInUp"
            data-wow-delay="0.5s"
          >
            <div className="image">
              <img
                decoding="async"
                src={`${imgUrl}/${service.image}`}
                alt={service.title}
              />
            </div>
          </div>
          <div
            className="col-lg-5 col-md-5 col-sm-12 content-column wow fadeInUp"
            data-wow-delay="0.5s"
          >
            <div className="content">
              <div className="sub-title">{service.title}</div>
              <h2 className="sec-title small mb-30">{service.subTitle}</h2>
              <div className="text-two">{service.description}</div>
              <div className="time">{service.time}</div>
              <div className="link-btn">
                {/* <Link
                  to="/aminities"
                  className="theme-btn btn-style-two btn-lg"
                >
                  <span>VIEW More</span>
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    ));

  return (
    <div className="container-xxl ">
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h6 className="section-title text-center text-primary">
            Amenities
          </h6>
          <h1 className="mb-5">
            <span className="text-primary">Amenities</span>
          </h1>
        </div>
        <div className="row g-4">{serviceData}</div>
      </div>
    </div>
  );
};

export default Service;
