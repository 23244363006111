import React, { useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { roomBooking } from "../redux/room-booking/bookingSlice";
import 'react-toastify/dist/ReactToastify.css';

const RoomBooking = ({ roomType, closeModal }) => {
  const dispatch = useDispatch();

    const fullname=useRef(null);
    const email=useRef(null);
    const mobile=useRef(null);
    const address=useRef(null);
    const no_of_guest=useRef(null);
    const room_type=useRef(null);
    const no_of_room=useRef(null);
    const arrival_time=useRef(null);
    const checkout_time=useRef(null);

useEffect(()=>{
  if(roomType){
    room_type.current.value = roomType;
  }
},[roomType]);

  
  const handleSubmit = (e) => {
    e.preventDefault();
  const form = e.currentTarget;
  if (!form.checkValidity()) {
    e.stopPropagation();
    form.classList.add('was-validated');
  } else {
    dispatch(roomBooking({
      fullname: fullname.current.value,
      email: email.current.value,
      mobile: mobile.current.value,
      address: address.current.value,
      no_of_guest: no_of_guest.current.value,
      room_type: room_type.current.value,
      no_of_room: no_of_room.current.value,
      arrival_time: arrival_time.current.value,
      checkout_time: checkout_time.current.value,
    }));

      fullname.current.value = ""
      email.current.value = ""
      mobile.current.value = ""
      address.current.value = ""
      no_of_guest.current.value = ""
      room_type.current.value = ""
      no_of_room.current.value = ""
      arrival_time.current.value = ""
      checkout_time.current.value = ""
 
    form.classList.remove('was-validated');
  }
    closeModal();
  };

  return (
         <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="bookingModalLabel">
                  Please provide required information in below fields.
                </h5>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={closeModal}></button>
              </div>
              <div className="modal-body">
                <form
                  className="row g-3 needs-validation"
                  id="booking"
                  onSubmit={(e) => handleSubmit(e)}
                  noValidate
                >
                  <div className="col-md-6">
                    <label htmlFor="fullname" className="form-label" >
                      Full Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="fullname"
                      ref={fullname}
                      placeholder="Please Provide Your Full Name"
                      required
                      
                    />
                    <div className="invalid-feedback">
                      Please provide your full name.
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label htmlFor="email" className="form-label">
                      Email
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="email"
                      ref={email}
                      placeholder="example@example.com"
                      required
                      
                    />
                    <div className="invalid-feedback">
                      Email field is required.
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="address" className="form-label">
                      Address
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="address"
                      ref={address}
                      placeholder="Chabahil-07, Kathmandu"
                      required
                      
                    />
                    <div className="invalid-feedback">
                      Address field is required.
                    </div>
                  </div>
                  <div className="col-md-4">
                    <label htmlFor="mobile" className="form-label">
                      Mobile Number
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="mobile"
                      ref={mobile}
                      placeholder="98xxxxxxxx"
                     
                      
                    />
                    {/* <div className="invalid-feedback">
                      Contact number is required
                    </div> */}
                  </div>

                  <div className="col-md-4">
                    <label htmlFor="mobile" className="form-label">
                      Room Type
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="room_type"
                      ref={room_type}
                      placeholder="roomType"
                      required
                      
                      readOnly
                    />
                    <div className="invalid-feedback">
                      Please choose a room type.
                    </div>
                  </div>

                  <div className="col-3">
                    <label htmlFor="no_of_guest" className="form-label">
                      No.Of Guest
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="no_of_guest"
                      ref={no_of_guest}
                      placeholder="4"
                      required
                      
                    />
                    <div className="invalid-feedback">
                      Please choose a number of guests.
                    </div>
                  </div>
                  <div className="col-3">
                    <label htmlFor="no_of_room" className="form-label">
                      No.Of Rooms
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="no_of_room"
                      ref={no_of_room}
                      placeholder="2"
                      required
                      
                    />
                    <div className="invalid-feedback">
                      Please choose a number of room.
                    </div>
                  </div>

                  <div className="col-3">
                    <label htmlFor="arrival_time" className="form-label">
                      Check In
                    </label>
                    <input
                      type="date"
                      className="form-control datetimepicker-input"
                      placeholder="Check In"
                      data-target="#date1"
                      data-toggle="datetimepicker"
                      id="arrival_time"
                      ref={arrival_time}
                      required
                      
                    />
                    <div className="invalid-feedback">
                      Please choose a check in date.
                    </div>
                  </div>
                  <div className="col-3">
                    <label htmlFor="checkout_time" className="form-label">
                      Checkout
                    </label>
                    <input
                      type="date"
                      className="form-control datetimepicker-input"
                      placeholder="Check out"
                      data-target="#date2"
                      data-toggle="datetimepicker"
                      id="checkout_time"
                      ref={checkout_time}
                      required
                      
                    />
                    <div className="invalid-feedback">
                      Please choose a check out date.
                    </div>
                  </div>
                  <div className="col-12 d-grid">
                    <button type="submit" className="btn btn-primary">
                      Book Now
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
  );
};

export default RoomBooking;
