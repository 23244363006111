import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Services = () => {
  const { services, imgUrl } = useSelector((state) => state.services);


  let serviceData =
    services &&
    services.map((service, idx) => (
      <div className="service-block-three" key={idx}>
        <div className="row">
          <div className="col-lg-7 image-column">
            <div className="image">
              <img
                decoding="async"
                src={`${imgUrl}/${service.image}`}
                alt={service.title}
              />
            </div>
          </div>
          <div className="col-lg-5 content-column">
            <div className="content">
              <div className="sub-title">{service.title}</div>
              <h2 className="sec-title small mb-30">{service.subTitle}</h2>
              <div className="text-two">{service.description}</div>
              <div className="time">{service.time}</div>
              <div className="link-btn">
                {/* <Link to="" className="theme-btn btn-style-two btn-lg">
                  <span>Read More</span>
                </Link> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    ));

  return (
    <div>
      {/* <div
        className="container-fluid page-header mb-5 p-0"
        style={{ backgroundImage: "url(/assets/img/carousel-1.jpg)" }}
      >
        <div className="container-fluid page-header-inner py-5 d-flex align-items-end">
          <div className="container text-center pb-5 mt-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Aminities
            </h1>
          </div>
        </div>
      </div> */}

      <div className="container-xxl">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title text-center text-primary">
              OUR Aminities
            </h6>
            <h1 className="mb-5">
              {/* <span className="text-primary">Contact</span> */}
              Explore Our Aminities
            </h1>
          </div>

          {/* Main Content */}
          <div className="row g-4">{serviceData}</div>
        </div>
      </div>
    </div>
  );
};

export default Services;
