import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";

const RecentBlogs = () => {
  const { blogs, imgUrl } = useSelector((state) => state.blogs);

  let BlogSideList =
    blogs &&
    blogs
      .slice()
      .sort((a, b) => (a.created_at > b.created_at ? -1 : 1))
      .map((blog, idx) => (
        <div key={idx} className="card mb-3">
          {blog && blog.image ? (
            <img
              src={`${imgUrl}/${blog.image}`}
              className="card-img-top"
              alt={blog.title}
            />
          ) : (
            ""
          )}
          <div className="card-body">
            <h5 className="card-title">{blog.title}</h5>
            <p className="card-text">{blog.short_description}</p>
            <a href={`/blog/${blog.slug}`} className="stretched-link">
              Read more ...
            </a>
            <p className="card-text">
              <small className="text-muted">
                {" "}
                Last updated{" "}
                {blog && blog.created_at
                  ? moment(blog.created_at).utc().startOf("hour").fromNow()
                  : ""}
              </small>
            </p>
          </div>
        </div>
      ));
  return <div>{BlogSideList}</div>;
};

export default RecentBlogs;
