import axios from "axios";
import { linkurl } from "../linkurl";

const API_URL = linkurl;

// Get All Packages
const packages = async () => {
  const res = await axios.get(`${API_URL}/packages`);
  
  if (res.data.status === 200) {
    return res.data;
  }
};

// Get Single Package
const singlePackage = async (id) => {
  const res = await axios.get(`${API_URL}/package/${id}`);
  if (res.data.status === 200) {
    return res.data;
  }
};

const packageService = {
  packages,
  singlePackage,
};
export default packageService;
