import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import termsServices from "./termsServices";

const initialState = {
  terms: [],
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get All terms
export const terms = createAsyncThunk("terms", async (thunkAPI) => {
  try {
    return await termsServices.terms();
  } catch (err) {
    const message =
      (err.response && err.response.data && err.response.data.message) ||
      err.message ||
      err.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const termsSlice = createSlice({
  name: "terms",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(terms.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(terms.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.terms = action.payload.terms[0];
      })
      .addCase(terms.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.terms = null;
      });
  },
});

export const { reset } = termsSlice.actions;
export default termsSlice.reducer;
