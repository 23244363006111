import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import blogServices from "./blogServices";

const initialState = {
  blogs: [],
  blog: {},
  imgUrl: {},
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get All Blogs
export const blogs = createAsyncThunk("blogs", async (thunkAPI) => {
  try {
    return await blogServices.blogs();
  } catch (err) {
    const message =
      (err.response && err.response.data && err.response.data.message) ||
      err.message ||
      err.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Get Blog By Id
export const singleBlog = createAsyncThunk(
  "singleBlog",
  async (slug, thunkAPI) => {
    try {
      return await blogServices.blog(slug);
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const blogSlice = createSlice({
  name: "blogs",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(blogs.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(blogs.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.blogs = action.payload.blogs;
        state.imgUrl = action.payload.image_url;
      })
      .addCase(blogs.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.blogs = null;
      })

      // Get Blog By Id
      .addCase(singleBlog.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(singleBlog.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.blog = action.payload.blog;
        state.imgUrl = action.payload.image_url;
      })
      .addCase(singleBlog.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.blog = null;
      });
  },
});

export const { reset } = blogSlice.actions;
export default blogSlice.reducer;
