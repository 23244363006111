import React from "react";
import { imgLink } from "../../redux/linkurl";


const Whyus = ({ whyus, imgUrl }) => {
  console.log("ddffd",imgUrl)
  return (
    <div>
      <div className="container-xxl py-2">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title text-center text-primary">
              Why us ?
            </h6>
            <h1 className="mb-5">
              Why
              <span className="text-primary"> Choose us ?</span>
            </h1>
          </div>
          <div className="row g-4">
            <div className="service-block-three">
              <div className="row">
                {
                  whyus && whyus.image ? (
                    <div className="col-lg-7 image-column">
                      <div className="image">
                        <img
                          src={`${imgLink}/${whyus.image}`}
                          className="card-img-top"
                          alt="whyus-img"
                          decoding="async"
                        />
                      </div>
                    </div>
                  ) : ""
                }
                {
                  whyus && whyus.text ? (
                    <div className="col-lg-5 content-column">
                      <div className="content">
                        <div className="why-us text-two">
                          <div dangerouslySetInnerHTML={{ __html: whyus.text }} className="wwl"/>
                        </div>

                        <div className="why-us">
                          <div className="icon-list">
                            <ul>
                              {
                                whyus && whyus.services && whyus.services.map((service,idx) => (
                                  <li key={idx}>
                                    <i className="flaticon-checkmark"></i>{service.service}
                                  </li>
                                ))
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : ""
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Whyus;
