import React from 'react';
import './Topheader.css';
import { useSelector } from 'react-redux';
import { useSpring, animated } from 'react-spring';
import Header from './Header';

const TopHeader = () => {
  const { setting } = useSelector((state) => state.setting);


  return (
    <div className='container-fluid p-0'>
      <div className='row'>
        <div className='col-2 d-none d-md-block'>
        <a className='' href='/'>
            <img src='/assets/logo/logo.png' alt='logo' style={{width:"110%"}} />
          </a>
        </div>
        <div className='col-10'>
          <div className='row d-none d-md-block' >
          <div className='header-img'>
          <img src='/assets/top/topheader.jpg'className='img-fluid' width="100%"  alt='header Image' />
          </div>
          </div>
       
         
        </div>
        {/* <div className='row'>
            <Header/>
          </div> */}
   
        {/* <div className='d-flex justify-content-end align-items-center'>
        
          <a className='d-none d-lg-block logo' href='/'>
            <img src='/assets/logo/logo.png' alt='logo' width='155px' />
          </a>

          <div className='d-none d-md-block header-img'>
    
            <img src='/assets/top/topheader.jpg'className='img-fluid' alt='header Image' />
          

          </div>
        </div> */}
      </div>
    </div>
  );
};

export default TopHeader;
