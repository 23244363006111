import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Packages.css'; // Update the path for your custom styles
import { useSelector } from 'react-redux';
import PackageBooking from '../../../booking-request/PackageBooking';
import { Link } from 'react-router-dom';

const Packages = () => {
  const { popular_packages, imgUrl } = useSelector((state) => state.packages);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  let packageList =
  popular_packages &&
  popular_packages.map((packageData, idx) => (
    <div
      className="col-lg-4 col-md-6 wow fadeInUp"
      data-wow-delay={`0.${idx}s`}
      key={idx}
    >
      <div className={`room-item shadow rounded overflow-hidden h-100 ${isModalOpen ? '' : 'costumehover'
        }`}>
        <div className="position-relative">
          {packageData && packageData.image ? (
            <img
              className="img-fluid"
              src={`${imgUrl}/${packageData.image}`}
              alt=""
            />
          ) : (
            ""
          )}
          <small className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4">
            Rs.{packageData.price}
          </small>
        </div>
        <div className="p-4 mt-2">
        <div className="d-flex justify-content-between align-items-center mb-3">
    <div className="title-container">
      <h5 className="mb-0 main-head">{packageData.title}</h5>
      <div className="sub-heading">
      <p className="mb-0  text-right ">{packageData.days}</p>

      </div>
    </div>
  </div>

          <p className="text-body mb-3 pt-3">
            {packageData.short_describe.substr(0, 110) + " ..."}
          </p>
          <div className="d-flex justify-content-between">
            <Link
              className="btn btn-sm btn-primary rounded py-2 px-4"
              to={`/package/${packageData.slug}`}
              state={{ id: packageData.id }}
            >
              View Detail
            </Link>
            <button 
            className="btn btn-sm btn-dark rounded "
            data-bs-toggle="modal"
            data-bs-target={`#model12${packageData?.id}`} // Assuming you want to access the room's ID
           onClick={openModal}
            >
              Book Now
            </button>
            {/* Modal Form */}
            <div className="modal fade" id={`model12${packageData.id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <PackageBooking packageType={packageData.title} closeModal={closeModal} />
            </div>
          </div>
        </div>
      </div>
    </div>
  ));

  // const settings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 500,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 4000,
  //   centerMode: true,
  //   centerPadding: '0',
  //   responsive: [
  //     {
  //       breakpoint: 1024,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         infinite: true,
        
  //       }
  //     },
  //     {
  //       breakpoint: 600,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1,
  //         initialSlide: 1
  //       }
  //     },
  //     {
  //       breakpoint: 480,
  //       settings: {
  //         slidesToShow: 1,
  //         slidesToScroll: 1
  //       }
  //     }
  //   ]
  // };



  return (
//     <div className="carousel-container mt-5">
//            <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
      
//           <h1 className="mb-4">
//             Take a Look <span className="text-primary">At Chitwan</span>
//           </h1>
//         </div>
//       <Slider {...settings}>
//         {popular_packages && popular_packages.map((pac, index) => (
//           <div className="carousel-slide" key={index}>
            
//             <img src={`${imgUrl}/${pac.image}`}className="carousel-background" alt={`Background ${index}`} />
//             <div className="carousel-content" style={{ padding: "50px" }}>

//     <img src={`${imgUrl}/${pac.image}`} alt={`Slide ${index}`} />
//     <div className='package-title '>
//       <h5>{pac.title}</h5>
//     </div>

// </div>
             
           
//           </div>
//         ))}
//       </Slider>
//     </div>


<div className="container-xxl py-5">
<div className="container">
  <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
    {/* <h1 className="section-title text-center text-primary">
      Our Packages List
    </h1> */}
    <h1 className="mb-2">
    Our  <span className="text-primary">Packages</span>
    </h1>
  </div>
  <div className="row g-4">{packageList}</div>
</div>
</div>
  );
};

export default Packages;
