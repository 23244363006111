import axios from "axios";
import { linkurl } from "../linkurl";

const API_URL = linkurl;

// Get All Teams
const payments = async () => {
  const res = await axios.get(`${API_URL}/mode-of-payments`);
  if (res.data.status === 200) {
    return res.data;
  }
};

const paymentService = {
  payments,
};
export default paymentService;
