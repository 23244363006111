import React from "react";
import { Link } from "react-router-dom";

const Video = () => {
  return (
    <>
      <div className="container-xxl py-5 px-0 wow zoomIn" data-wow-delay="0.1s">
        <div className="row g-0">
          <div className="col-md-6 bg-green d-flex align-items-center">
            <div className="p-5">
              <h6 className="section-title text-start text-white mb-3">
                Luxury Living
              </h6>
              <h2 className="text-white mb-4">
              
In the UNIQUE WILD RESORT, you will feel at home even though you are away from home. People say we are hospitable, that's our nature.

              </h2>
              
              <Link
                to="/rooms"
                className="btn btn-primary btn-sm  me-3"
              >
                Our Rooms
              </Link>
              <Link to="/packages" className="btn btn-light btn-sm " style={{backgroundColor:"#adadad"}}>
                Our Packages
              </Link>
            </div>
          </div>
          <div className="col-md-6">
            <div className="video">
              <button
                type="button"
                className="btn-play"
                data-bs-toggle="modal"
                data-src="https://www.youtube.com/embed/SHwA938BkAo"
                data-bs-target="#videoModal"
              >
                <span></span>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="modal fade"
        id="videoModal"
        tabIndex="-1"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content rounded-0">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Youtube Video
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body">
              {/* <!-- 16:9 aspect ratio --> */}
              <div className="ratio ratio-16x9">
                <iframe
                  title="unique_video"
                  className="embed-responsive-item"
                  src="https://www.youtube.com/embed/SHwA938BkAo"
                  id="video"
                  allowFullScreen
                  allowscriptaccess="always"
                  allow="autoplay"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Video;
