import React from "react";
import { useSelector } from "react-redux";
import { imgLink } from "../../../redux/linkurl";

const Teams = ({ board_members, staffs, imgUrl }) => {
  let gmdata = board_members[0];


  let teamData =
    board_members &&
    board_members.slice(1, 5).map((team, idx) => (
      <div
        className="col"
        data-wow-delay="0.1s"
        key={idx}
      >
        <div className="rounded shadow overflow-hidden h-100">
          <div className="position-relative">
            {team && team.image ? (
              <img
                className="img-fluid"
                src={`${imgLink}/${team.image}`}
                alt="pro-pic"
              />
            ) : (
              ""
            )}

            <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
              {team && team.facebook ? (
                <a
                  className="btn btn-square btn-primary mx-1"
                  href={team.facebook}
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
              ) : (
                ""
              )}

              {team && team.twitter ? (
                <a
                  className="btn btn-square btn-primary mx-1"
                  href={`mailTo:${team.twitter}`}
                >
                  <i className="fa fa-envelope"></i>
                </a>
              ) : (
                ""
              )}

              {team && team.instagram ? (
                <a
                  className="btn btn-square btn-primary mx-1"
                  href={team.instagram}
                >
                  <i className="fab fa-instagram"></i>
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="text-center p-4 mt-3">
            <h5 className="fw-bold mb-0">
              {team && team.name ? team.name : ""}
            </h5>
            <small>{team && team.designation ? team.designation : ""}</small>
          </div>
        </div>
      </div>
    ));



  let staffData =
    staffs &&
    staffs.slice(0, 5).map((team, idx) => (
      <div
        className="col"
        data-wow-delay="0.1s"
        key={idx}
      >
        <div className="rounded shadow overflow-hidden h-100">
          <div className="position-relative">
            {team && team.image ? (
              <img
                className="img-fluid"
                src={`${imgLink}/${team.image}`}
                alt="pro-pic"
              />
            ) : (
              ""
            )}

            <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
              {team && team.facebook ? (
                <a
                  className="btn btn-square btn-primary mx-1"
                  href={team.facebook}
                >
                  <i className="fab fa-facebook-f"></i>
                </a>
              ) : (
                ""
              )}

              {team && team.twitter ? (
                <a
                  className="btn btn-square btn-primary mx-1"
                  href={`mailTo:${team.twitter}`}
                >
                  <i className="fa fa-envelope"></i>
                </a>
              ) : (
                ""
              )}

              {team && team.instagram ? (
                <a
                  className="btn btn-square btn-primary mx-1"
                  href={team.instagram}
                >
                  <i className="fab fa-instagram"></i>
                </a>
              ) : (
                ""
              )}
            </div>
          </div>
          <div className="text-center p-4 mt-3">
            <h5 className="fw-bold mb-0">
              {team && team.name ? team.name : ""}
            </h5>
            <small>{team && team.designation ? team.designation : ""}</small>
          </div>
        </div>
      </div>
    ));
  return (
    <div className="container-xxl">
      <div className="container-fluid">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h6 className="section-title text-center text-primary">
            Our Team
          </h6>
          <h1 className="mb-5">
            Board {" "}
            <span className="text-primary">of Director</span>
          </h1>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-3 mb-4">
            {
              gmdata &&
              <div
                className="col"
                data-wow-delay="0.1s"

              >
                <div className="rounded shadow overflow-hidden h-100">
                  <div className="position-relative">
                    {gmdata && gmdata.image ? (
                      <img
                        className="img-fluid"
                        src={`${imgLink}/${gmdata.image}`}
                        alt="pro-pic"
                      />
                    ) : (
                      ""
                    )}

                    <div className="position-absolute start-50 top-100 translate-middle d-flex align-items-center">
                      {gmdata && gmdata.facebook ? (
                        <a
                          className="btn btn-square btn-primary mx-1"
                          href={gmdata.facebook}
                        >
                          <i className="fab fa-facebook-f"></i>
                        </a>
                      ) : (
                        ""
                      )}

                      {gmdata && gmdata.twitter ? (
                        <a
                          className="btn btn-square btn-primary mx-1"
                          href={`mailTo:${gmdata.twitter}`}
                        
                        >
                          <i className="fa fa-envelope"></i>
                        </a>
                      ) : (
                        ""
                      )}

                      {gmdata && gmdata.instagram ? (
                        <a
                          className="btn btn-square btn-primary mx-1"
                          href={gmdata.instagram}
                        >
                          <i className="fab fa-instagram"></i>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="text-center p-4 mt-3">
                    <h5 className="fw-bold mb-0">
                      {gmdata && gmdata.name ? gmdata.name : ""}
                    </h5>
                    <small>{gmdata && gmdata.designation ? gmdata.designation : ""}</small>
                  </div>
                </div>
              </div>

            }

          </div>
        </div>
        <div className="row row-cols-1 row-cols-md-4 row-cols-lg-4 g-4">{teamData}</div>
      </div>




      {
        staffs && staffs.length > 0 &&
        <div className="container-fluid mt-5">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            {/* <h6 className="section-title text-center text-primary">
      Our Staff
    </h6> */}
            <h1 className="mb-5">
              Our{" "}Staffs
              {/* <span className="text-primary">Staffs</span> */}
            </h1>
          </div>
          <div className="row row-cols-1 row-cols-md-4 row-cols-lg-4  g-4">{staffData}</div>
        </div>

      }

    </div>
  );
};

export default Teams;
