import axios from "axios";
import { linkurl } from "../linkurl";
import _ from 'lodash';

const API_URL = linkurl;

// Get All Blogs
const blogs = async () => {
  const res = await axios.get(`${API_URL}/blogs`);
  if (res.data.status === 200) {
    return res.data;
  }
};

// Get Blog
const blog = async (blogId) => {
  const res = await axios.get(`${API_URL}/blog/${blogId}`);
  if (res.data.status === 200) {
    return res.data;
  }
};

const blogServices = {
  blogs,
  blog,
};
export default blogServices;
