import React from "react";
import { useSelector } from "react-redux";
import "./Footer.css"

const Footer = ({ setting }) => {
  const { agencies, imgUrl } = useSelector((state) => state.agencies);
  const { payments, logoUrl } = useSelector((state) => state.payments);
  const { services } = useSelector((state) => state.services);
 


  return (
    <>
      <div
      className="container-fluid footer-bg-color text-light footer wow fadeIn mt-4"
      data-wow-delay="0.1s"
    >
      <div className="container">
        <div className="row g-5">
          <div className="col-md-6 col-lg-3">
            <h6 className="section-title text-start text-white mb-4">
              Company
            </h6>
            <a className="btn btn-link" href="/about">
              About UWR
            </a>
            <a className="btn btn-link" href="/contact">
              Contact Us
            </a>
            <img src="/assets/qr/qr.png" alt="qr scanner" width="150px" />
           
          </div>

          <div className="col-md-6 col-lg-2">
            <h6 className="section-title text-start text-white mb-4">
              Quick Link
            </h6>
{/* {serviceData} */}
          <a className="btn btn-link" href="/">   Home</a>
          <a className="btn btn-link" href="/about">   About</a>
          <a className="btn btn-link" href="/amenities">   Amenities</a>
          <a className="btn btn-link" href="/rooms">   Accommodations</a>
          <a className="btn btn-link" href="/packages">   Packages</a>
          <a className="btn btn-link" href="/blogs">   Blogs</a>
          <a className="btn btn-link" href="/gallery">   Gallery</a>




         

          </div>

          <div className="col-md-6 col-lg-4">
            <h6 className="section-title text-start text-white mb-4">
              Contact
            </h6>
            <p className="mb-2">
              <i className="fa fa-map-marker-alt me-3"></i>
              <span>
              {setting && setting.address ? setting.address : ""}
              </span>
            </p>
            <p className="mb-2">
              <i className="fa fa-phone-alt me-3"></i>
              {setting && setting.phone ? 
              <a href={`tel:${setting.phone}`} style={{color:"white"}}>+977-056-{setting.phone}</a>  : ""}
            </p>

           
            <p className="mb-2">
              <i className="fa fa-mobile-alt me-3"></i>
              {setting && setting.phone2 ? 
              <a href={`tel:${setting.phone2}`} style={{color:"white"}}>+977-{setting.phone2}</a>  : ""}
            </p>
            <p className="mb-2">
              <i className="fa fa-envelope me-3"></i>
              {setting && setting.email ?
              <a href={`mailTo:${setting.email}`} style={{color:"white"}}> {setting.email }</a> : ""}
            </p>
            <br/> <br/>
            <div className="d-flex pt-2">
              {setting && setting.twitter ? (
                <a
                  className="btn btn-social"
                  href={setting.twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/social icons/x.png" alt="twitter" width="25px" />
                </a>
              ) : (
                ""
              )}

              {setting && setting.pinterest ? (
                <a
                  className="btn btn-social"
                  href={setting.pinterest}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/social icons/tiktok.png" alt="tiktok" width="25px" />
                </a>
              ) : (
                ""
              )}

              {setting && setting.facebook ? (
                <a
                  className="btn btn-social"
                  href={setting.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/social icons/face.png" alt="facebook" width="25px" />
                </a>
              ) : (
                ""
              )}

              {setting && setting.instagram ? (
                <a
                  className="btn btn-social"
                  href={setting.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/social icons/insta.png" alt="instagram" width="25px" />
                </a>
              ) : (
                ""
              )}

              {setting && setting.youtube ? (
                <a
                  className="btn btn-social"
                  href={setting.youtube}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/social icons/youtube.png" alt="youtube" width="25px" />
                </a>
              ) : (
                ""
              )}

              {setting && setting.linkedin ? (
                <a
                  className="btn btn-social"
                  href= {`tel:${setting.linkedin}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/social icons/whatsapp.png" alt="whatsapp" width="25px" />
                </a>
              ) : (
                ""
              )}
            </div>


            <div className="d-flex flex-wrap pt-2">
{/* {bookingSite} */}

            </div>
          </div>

          <div className="col-md-6 col-lg-3">
          <h6 className="section-title text-start text-white mb-4">
              Location Map
            </h6>
            <div className="bg-light py-2" style={{boxShadow:"3px 5px 2px 1px black", borderRadius:"10px"}}>
     

              <div className="row">

              <iframe
          className="position-relative rounded w-100 h-100"
          src={setting.map}
          title="map"
          style={{ minHeight: "190px", border: "0" }}
          allowFullScreen=""
          aria-hidden="false"
          tabIndex="0"
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
              </div>
            </div>
          </div>



        </div>
       
      </div>
      
    </div>
    <div className="container-fluid footer-end mt-0">
       <div className="container con">
        <div className="copyright">
          <div className="row">
            <div className="col-md-4 mb-3 mb-md-0">
            <span style={{textAlign:"right"}}>
              Develop By{" "}
              <a className="border-bottom" href="/">
                blackhawk
              </a>

              </span>
              </div>
            <div className="col-md-4 d-flex justify-content-center mb-3 mb-md-0">
              <div>
                    &copy;{" "} {new Date().getFullYear() }
                  <a className="border-bottom" href="/">
                  {" "} Unique Wild Resort.
                  </a>
              </div>
            </div>
            <div className="col-md-4 text-center text-md-end">
              <div className="footer-menu">
                <a href="/">Home</a>
                {/* <a href="/">About</a>
                <a href="/">Amenities</a>
                <a href="/">Accommodations</a> */}
                <a href="/faqs">FQAs</a>
            <a  href="/terms">
              Terms & Condition
            </a>
              </div>
            </div>
          </div>
        </div>

      </div>
    

      <img  className="img-fluid d-none d-md-block img-footer" src="./assets/img/unique_footer.png" alt="footer image" style={{width:"100%"}}/>

     
    </div>

    </>
    
  );
};

export default Footer;
