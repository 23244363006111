import React from 'react'
import { useSelector } from 'react-redux';
import './PaymentOption.css'

const PaymentOptiom = () => {
  const { agencies,imgUrl } = useSelector((state) => state.agencies);
  const { payments } = useSelector((state) => state.payments);

  let bookingSite =
    agencies &&
    agencies.slice(0, 6).map((agency, idx) => (
      <div
        className="col"
        data-wow-delay="0.1s"
        key={idx}
      >
        {agency && agency.logo ? (
          <a href={agency.link} target="_blank" rel="noopener noreferrer">
            <img
              className="img-fluid "
              src={`${imgUrl}/${agency.logo}`}
              alt="booking-site"
              width="100%"
            />
          </a>

        ) : (
          ""
        )}
      </div>
    ));

  let paymentGateways =
    payments &&
    payments.slice(0, 6).map((payment, idx) => {

     return <div
      className="col"
      data-wow-delay="0.1s"
      key={idx}
    >
      {payment && payment.logo ? (
        <a href={payment.link} target="_blank" rel="noopener noreferrer">
          <img
            className="img-fluid customimg"
            src={`${imgUrl}/${payment.logo}`}
            alt="booking-site"
            width="100%"
          />
        </a>

      ) : (
        ""
      )}
    </div>


        // return payment && payment.logo ? (
        //   <a key={idx}href={payment.link} target="_blank" rel="noopener noreferrer" style={{width:"90px",marginRight:"10px"}}>
        //     <img
        //       className="img-fluid customimg"
        //       src={`${imgUrl}/${payment.logo}`}
        //       alt="booking-site"
        //       width="100%"
        //     />
        //   </a>
        // ) : (
        //   ""
        // )

        });
  return (
    <div className="container-xxl mt-5">
      <div className="container">

        <div className='row'>
          <div className='col-md-6'>
          <h3 className="text-center pb-2">
            Mode of
            <span className="text-primary">  Payment </span>
          </h3>
          <div className='d-flex flex-wrap'>
            {paymentGateways}
          </div>

          </div>
          <div className='col-md-6 cusBg'>
          <h3 className="text-center">
              Find
              <span className="text-primary">   Us  </span>

            </h3>
            <div className='d-flex flex-wrap'>
              {bookingSite}
            </div>

          </div>



        </div>


        {/* <div className='col-md-12 mt-4'>
          
            <h1 className="text-center">
              Find
              <span className="text-primary">   Us  </span>

            </h1>
            <div className='row row-cols-1 row-cols-md-8 g-4'>
              {bookingSite}
            </div>
          

        </div> */}


      </div>
    </div>
  )
}

export default PaymentOptiom