import React, { useEffect, useState } from 'react'
import Fancy from './Fancy'
import { useSelector } from 'react-redux'

const Gallery = () => {
    const { galleries, imgUrl } = useSelector((state) => state.galleries);

 

    const [category, setCategory] = useState(null);


    // useEffect(() => {
    //     if (galleries && galleries.data) {
    //         setCategory(galleries.data[0])
    //     }
    // }, [galleries])


    const catTitle = galleries && galleries ? galleries.map((gallery, idx) => (
        <li className="nav-item" key={idx}>
            <h5>{gallery.title}</h5>

                
 {
   
    <div className='row'>
   <Fancy options={{ infinite: false }}>
                {
                    gallery && gallery.gallerypictures
                    ? gallery.gallerypictures.map((gall, idx) => (
<>
                        <div className="col-md-3" data-aos="fade-up" data-aos-duration="1000" key={idx}>
                            <div data-fancybox="gallery"
                                data-src={`${imgUrl}/${gall.image}`}

                                type="button"
                            >
                                <img src={`${imgUrl}/${gall.image}`} className="img-fluid img-thumbnail" />
                            </div>
                        </div>
</>
                    )) : ""
                }
            </Fancy>
    </div>

 }
        </li>
    )) : ""

    return (
        <div className="container-xxl py-5">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title text-center text-primary">
              Our Gallery
            </h6>
            <h1 className="mb-5">
              Hotel <span className="text-primary">Gallery</span>
            </h1>
          </div>
          <div className="row g-4">
            {catTitle}
            {/* <Fancy options={{ infinite: false }}>
                {
                    galleries && galleries.gallerypictures
                    ? galleries.gallerypictures.map((gallery, idx) => (
<>
                        <div className="col-md-3" data-aos="fade-up" data-aos-duration="1000" key={idx}>
                            <div data-fancybox="gallery"
                                data-src={`${imgUrl}/${gallery.image}`}

                                type="button"
                            >
                                <img src={`${imgUrl}/${gallery.image}`} className="img-fluid img-thumbnail" />
                            </div>
                        </div>
</>
                    )) : ""
                }
            </Fancy> */}
          </div>
        </div>
      
      </div>
    )
}

export default Gallery

// {
//   gallery&& gallery.gallerypictures.map((gall,idx)=>(
//    <>
//    <img src={`${imgUrl}/${gall.image}`} className="img-fluid img-thumbnail" />
//    </>
//   ))
//  }