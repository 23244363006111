import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getSingleRoom } from "../../redux/rooms/roomSlice";
import RoomBookingContainer from "../../booking-request/RoomBookingContainer";

const SingleRoom = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { room } = useSelector((state) => state.room);
  const { id } = location.state;

  useEffect(() => {
    if (id) {
      dispatch(getSingleRoom(id));
    }
  }, [dispatch, id]);

  return (
    <>
      <div
        className="container-fluid page-header mb-5 p-0"
        style={{ backgroundImage: `url(${room.image_url}/${room.image})` }}
      >
        <div className="container-fluid page-header-inner py-5 d-flex align-items-end">
          <div className="container text-center pb-5 mt-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown ">
              Explore Room
            </h1>
          </div>
        </div>
      </div>

      <div className="container-xxl">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title text-center text-primary ">
              Explore Room
            </h6>
            <h1 className="mb-5">
              {/* <span className="text-primary ">Contact</span> */}
              {room && room.title ? room.title : ""}
            </h1>
          </div>

          {/* Main Content */}
          <div className="row g-4">
            <div className="col-12">
              <div className="row gy-4">
                {/* <p style={{ textAlign: "justify", fontSize:"16px", letterSpacing:"1.2px" }}>
                  {room && room.short_description ? room.short_description : ""}
                </p> */}
                <div className="col-md-8">
                  <div className="row p-3">
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          room && room.description ? room.description : "",
                      }}
                    ></div>
                  </div>
                  <div className="row mt-3 room-aminities mb-3">
                    <h1>Amenities</h1>
                    <div className="row">
                      {room && room.aircondition === 1 ? (
                        <div className="col-md-4 mb-3">
                          <i className="flaticon-air-conditioner"></i>Air
                          conditioner
                        </div>
                      ) : (
                        ""
                      )}
                      {room && room.cableTv === 1 ? (
                        <div className="col-md-4 mb-3">
                          <div>
                            <i className="flaticon-cable-tv"></i>Cable TV
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      {room && room.swimmingpool === 1 ? (
                        <div className="col-md-4 mb-3">
                          <div>
                            <i className="flaticon-pool"></i>Swimming pool
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {room && room.wifi === 1 ? (
                        <div className="col-md-4 mb-3">
                          <div>
                            <i className="flaticon-wifi-signal"></i>High speed
                            WiFi
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      {room && room.balconyTerrace === 1 ? (
                        <div className="col-md-4 mb-3">
                          <i className="flaticon-terrace"></i>Balcony or Terrace
                        </div>
                      ) : (
                        ""
                      )}

                      {room && room.kettle === 1 ? (
                        <div className="col-md-4 mb-3">
                          <i className="flaticon-kettle"></i>Kettle Tea
                        </div>
                      ) : (
                        ""
                      )}

                      {room && room.bathtub === 1 ? (
                        <div className="col-md-4 mb-3">
                          <i className="flaticon-bath-1"></i>Bathtub
                        </div>
                      ) : (
                        ""
                      )}

                      {room && room.hairdryer === 1 ? (
                        <div className="col-md-4 mb-3">
                          <i className="flaticon-hair-dryer"></i>Hair Dryer
                        </div>
                      ) : (
                        ""
                      )}

                      {room && room.breakfast === 1 ? (
                        <div className="col-md-4 mb-3">
                          <i className="flaticon-breakfast"></i>Breakfast
                          Included
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>

                  <div className="row mb-3">
                    <h3>House Rules</h3>                   
                  </div>

                  <div className="row mb-3">
                    <h3>Children and Extra beds</h3>
                    <div className="room-info">
                      {room && room.extrabeds ? room.extrabeds : ""}
                    </div>
                  </div>
                  <div className="row mb-3">
                    <h3>Fitness Medical, and Health</h3>
                    <div className="room-info">
                      {room && room.health_suggestion
                        ? room.health_suggestion
                        : ""}
                    </div>
                    <div></div>
                  </div>
                  <div className="row mb-3">
                    <h3>Cancellation</h3>
                    <div className="room-info">
                      {room && room.cancellation ? room.cancellation : ""}
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <button className="btn-success btn-lg" type="button"
                    data-bs-toggle="modal"
                    data-bs-target={`#model${room?.id}`}>
                      Book This Room
                    </button>
                    {/* Modal Form */}
                    <div className="modal fade" id={`model${room.id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <RoomBookingContainer roomType={room.title} />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          room && room.room_tariff ? room.room_tariff : "",
                      }}
                    ></div>
                  </div>
                  <div className="row my-3">
                    <h6>Check In</h6>
                    <span>{room && room.checkin ? room.checkin : ""}</span>
                  </div>
                  <div className="row my-3">
                    <h6>Check Out</h6>
                    <span>{room && room.checkout ? room.checkout : ""}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SingleRoom;
