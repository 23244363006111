import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { singlePackage } from "../../redux/packages/packageSlice";
import PackageBooking from "../../booking-request/PackageBooking";

const Package = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { packageSingle, imgUrl } = useSelector((state) => state.package);
  const { id } = location.state;

  useEffect(() => {
    if (id) {
      dispatch(singlePackage(id));
    }
  }, [dispatch, id]);

  return (
    <>
      <div
        className="container-fluid page-header mb-5 p-0"
        style={{ backgroundImage: `url(${imgUrl}/${packageSingle.image})` }}
      >
        <div className="container-fluid page-header-inner py-5 d-flex align-items-end">
          <div className="container text-center pb-5 mt-5">
            <h1 className="display-3 text-white mb-3 animated slideInDown">
              Explore Chitwan
            </h1>
          </div>
        </div>
      </div>

      <div className="container-xxl">
        <div className="container">
          <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
            <h6 className="section-title text-center text-primary">
              Explore Chitwan
            </h6>
            <h1 className="mb-5">
              {/* <span className="text-primary">Contact</span> */}
              {packageSingle && packageSingle.title ? packageSingle.title : ""}
            </h1>
          </div>

          {/* Main Content */}
          <div className="row g-4">
            <div className="col-12">
              <div className="row gy-4">
                <p style={{ textAlign: "justify" }}>
                  {packageSingle && packageSingle.short_describe
                    ? packageSingle.short_describe
                    : ""}
                </p>
                <div className="col-md-8">
                  <div className="row p-3">
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          packageSingle && packageSingle.long_description
                            ? packageSingle.long_description
                            : "",
                      }}
                    ></div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row">
                    <button className="btn-success btn-lg" type="button"
                    data-bs-toggle="modal"
                    data-bs-target={`#model${packageSingle?.id}`}>
                      Book This Trip
                    </button>
                    {/* Modal Form */}
                    <div className="modal fade" id={`model${packageSingle.id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                      <PackageBooking packageType={packageSingle.title} />
                    </div>
                  </div>
                  <div className="row my-3">
                    <div
                      dangerouslySetInnerHTML={{
                        __html:
                          packageSingle && packageSingle.tariff
                            ? packageSingle.tariff
                            : "",
                      }}
                    ></div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Package;
