import React from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { useSpring, animated } from 'react-spring';

import Footer from "./Footer";
import Header from "./Header";
import TopHeader from "./TopHeader";
import BackToTopButton from "../../goto/BackToTopButton";


const Layout = () => {
  const { setting } = useSelector((state) => state.setting);
  // const headerAnimation = useSpring({
  //   transform: "translateX(0%)",
  //   from: { transform: "translateX(-100%)" },
  //   delay: 2000,
  // });
  // const topHeaderAnimation = useSpring({
  //   transform: "translateY(0%)",
  //   from: { transform: "translateY(-500%)" },
  //   delay: 1000,
  // });

  return (
    <div className="container-xxl bg-white p-0">
       {/* <animated.div style={topHeaderAnimation}>
       <div style={{ opacity: topHeaderAnimation.opacity }}>
       < TopHeader/>
</div>
       </animated.div>
       <animated.nav style={headerAnimation}>
       <Header setting={setting} />
       </animated.nav>
       */}
  
  < TopHeader/>
  <Header setting={setting} />

      <Outlet />
  
      <Footer setting={setting} />
      <BackToTopButton/>
    </div>
  );
};

export default Layout;
