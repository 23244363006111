import { Routes, Route } from "react-router-dom";
import RoomBooking from "./booking-request/RoomBooking";
import About from "./pages/about/About";
import Blogs from "./pages/blogs/Blogs";
import SingleBlog from "./pages/blogs/SingleBlog";
import Contact from "./pages/contact/Contact";
import Faq from "./pages/faqs/Faq";
import Rooms from "./pages/home-page/home-components/Rooms";
import HomePage from "./pages/home-page/HomePage";
import Layout from "./pages/layout/Layout";
import Package from "./pages/packages/Package";
import PackagesList from "./pages/packages/PackagesList";
import SingleRoom from "./pages/rooms/SingleRoom";
import Services from "./pages/services/Services";
import Gallery from "./pages/gallery/Gallery";
import Reservation from "./pages/Reservation/Reservation";
import Terms from "./pages/terms/Terms";

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WelcomeChitwan from "./pages/welcomeChitwan/WelcomeChitwan";


function App() {
  return (
    <>
        <ToastContainer />
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route path="/" element={<HomePage />} />
          <Route path="/about" element={<About />} />
          <Route path="/about-chitwan" element={<WelcomeChitwan />} />

          <Route path="/rooms" element={<Rooms />} />
          <Route path="/room/:slug" element={<SingleRoom />} />
          <Route path="/room-booking-process" element={<RoomBooking />} />
          <Route path="/packages" element={<PackagesList />} />
          <Route path="/package/:slug" element={<Package />} />
          <Route path="/amenities" element={<Services />} />
          <Route path="/blogs" element={<Blogs />} />
          <Route path="/blog/:slug" element={<SingleBlog />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/faqs" element={<Faq />} />
          <Route path="/reservation" element={<Reservation />} />

          <Route path="/gallery" element={<Gallery />} />
          <Route path="/terms" element={<Terms />} />

         
        </Route>
      </Routes>
    </>
  );
}

export default App;
