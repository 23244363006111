import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import packageService from "./packageServices";

const initialState = {
  special_packages: [],
  popular_packages: [],
  packageSingle: {},
  imgUrl: "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get All Packages
export const packages = createAsyncThunk("packages", async (thunkAPI) => {
  try {
    return await packageService.packages();
  } catch (err) {
    const message =
      (err.response && err.response.data && err.response.data.message) ||
      err.message ||
      err.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// Get Package By Id
export const singlePackage = createAsyncThunk(
  "singlePackage",
  async (id, thunkAPI) => {
    try {
      return await packageService.singlePackage(id);
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const packageSlice = createSlice({
  name: "packages",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(packages.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(packages.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.popular_packages = action.payload.popular_packages;
        state.special_packages = action.payload.special_packages;
        state.imgUrl = action.payload.image_url;
      })
      .addCase(packages.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.packages = null;
      })
      // Get Package By Id
      .addCase(singlePackage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(singlePackage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.packageSingle = action.payload.package;
        state.imgUrl = action.payload.image_url;
      })
      .addCase(singlePackage.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.packageSingle = null;
      });
  },
});

export const { reset } = packageSlice.actions;
export default packageSlice.reducer;
