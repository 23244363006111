import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import teamServices from "./teamServices";

const initialState = {
  board_members: [],
  staffs:[],
  imgUrl: "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get All Teams
export const teams = createAsyncThunk("teams", async (thunkAPI) => {
  try {
    return await teamServices.teams();
  } catch (err) {
    const message =
      (err.response && err.response.data && err.response.data.message) ||
      err.message ||
      err.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const teamSlice = createSlice({
  name: "teams",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(teams.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(teams.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.board_members = action.payload.board_members;
        state.staffs = action.payload.staffs;
        state.imgUrl = action.payload.image_url;
      })
      .addCase(teams.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.teams = null;
      });
  },
});

export const { reset } = teamSlice.actions;
export default teamSlice.reducer;
