import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import bookingSiteServices from "./bookingSiteServices";

const initialState = {
  agencies: [],
  imgUrl: "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// Get All Teams
export const agencies = createAsyncThunk("agencies", async (thunkAPI) => {
  try {
    return await bookingSiteServices.agencies();
  } catch (err) {
    const message =
      (err.response && err.response.data && err.response.data.message) ||
      err.message ||
      err.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

export const bookingSiteSlice = createSlice({
  name: "agencies",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(agencies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(agencies.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.agencies = action.payload.agencies;
        state.imgUrl = action.payload.image_url;
      })
      .addCase(agencies.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.agencies = null;
      });
  },
});

export const { reset } = bookingSiteSlice.actions;
export default bookingSiteSlice.reducer;
