import React from "react";
import Location from "./Location";

const ContactForm = ({ setting }) => {
  return (
    <div className="row g-4 mt-4">
      <div className="col-md-6 wow fadeIn" data-wow-delay="0.1s">
        <div className="wow fadeInUp" data-wow-delay="0.2s">
          <form>
            <div className="row g-3">
              <div className="col-md-6">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="name"
                    placeholder="Your Name"
                  />
                  <label htmlFor="name">Your Name</label>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-floating">
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    placeholder="Your Email"
                  />
                  <label htmlFor="email">Your Email</label>
                </div>
              </div>
              <div className="col-12">
                <div className="form-floating">
                  <input
                    type="text"
                    className="form-control"
                    id="subject"
                    placeholder="Subject"
                  />
                  <label htmlFor="subject">Subject</label>
                </div>
              </div>
              <div className="col-12">
                <div className="form-floating">
                  <textarea
                    className="form-control"
                    placeholder="Leave a message here"
                    id="message"
                    style={{ height: "150px" }}
                  ></textarea>
                  <label htmlFor="message">Message</label>
                </div>
              </div>
              <div className="col-12">
                <button className="btn btn-primary w-100 py-3" type="submit">
                  Send Message
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className="col-md-6">
      <Location setting={setting} />
      </div>
      {/* <div className="col-sm-12">
      <h6 className="section-title text-center text-primary mb-4">
          Connect With us
        </h6>

        <div className="d-flex pt-2">
              {setting && setting.twitter ? (
                <a
                  className="btn btn-social"
                  href={setting.twitter}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/social icons/x.png" alt="twitter" width="25px" />
                </a>
              ) : (
                ""
              )}

              {setting && setting.pinterest ? (
                <a
                  className="btn btn-social"
                  href={setting.pinterest}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/social icons/tiktok.png" alt="tiktok" width="25px" />
                </a>
              ) : (
                ""
              )}

              {setting && setting.facebook ? (
                <a
                  className="btn btn-social"
                  href={setting.facebook}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/social icons/face.png" alt="facebook" width="25px" />
                </a>
              ) : (
                ""
              )}

              {setting && setting.instagram ? (
                <a
                  className="btn btn-social"
                  href={setting.instagram}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/social icons/insta.png" alt="instagram" width="25px" />
                </a>
              ) : (
                ""
              )}

              {setting && setting.youtube ? (
                <a
                  className="btn btn-social"
                  href={setting.youtube}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/social icons/youtube.png" alt="youtube" width="25px" />
                </a>
              ) : (
                ""
              )}

              {setting && setting.linkedin ? (
                <a
                  className="btn btn-social"
                  href= {`tel:${setting.linkedin}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src="/assets/social icons/whatsapp.png" alt="whatsapp" width="25px" />
                </a>
              ) : (
                ""
              )}
        </div>
      </div> */}
    </div>
  );
};

export default ContactForm;
