import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import roomService from "./roomServices";

const initialState = {
  rooms: [],
  room: {},
  imgUrl: "",
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: "",
};

// ========================== Get All Rooms ===================================

export const rooms = createAsyncThunk("rooms", async (thunkAPI) => {
  try {
    return await roomService.rooms();
  } catch (err) {
    const message =
      (err.response && err.response.data && err.response.data.message) ||
      err.message ||
      err.toString();
    return thunkAPI.rejectWithValue(message);
  }
});

// ================================= Get Room By Id =====================================
export const getSingleRoom = createAsyncThunk(
  "room",
  async (roomId, thunkAPI) => {
    try {
      return await roomService.room(roomId);
    } catch (err) {
      const message =
        (err.response && err.response.data && err.response.data.message) ||
        err.message ||
        err.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const roomSlice = createSlice({
  name: "rooms",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isSuccess = false;
      state.isError = false;
      state.message = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(rooms.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(rooms.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.rooms = action.payload;
        state.imgUrl = action.payload.image_url;
      })
      .addCase(rooms.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.rooms = null;
      })

      // =================== Get Room By Id =============================
      .addCase(getSingleRoom.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSingleRoom.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.room = action.payload.data;
        state.imgUrl = action.payload.image_url;
      })
      .addCase(getSingleRoom.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.room = null;
      });
  },
});

export const { reset } = roomSlice.actions;
export default roomSlice.reducer;
