import React from 'react'
import { useSelector } from "react-redux";
const AboutUniqueWild = () => {
    const { about } = useSelector((state) => state.about);
  return (
    <div className="container-xxl py-5">
    <div className="container">
      <div className="row g-5 align-items-center">
      <div className="col-lg-6">
       

<div className="row">
  <div className="col-lg-4 col-md-4 col-sm-4 mb-4 mb-lg-0">
     <img
      src="assets/aboutUnique/11.png"
      className="w-100 shadow-1-strong rounded mb-4"
      alt="Waves at Sea"
    />

    <img
      src="assets/aboutUnique/16.png"
      className="w-100 shadow-1-strong rounded mb-4"
      alt="Wintry Mountain Landscape"
    />
  </div>

  <div className="col-lg-4 col-md-4 col-sm-4 mb-4 mb-lg-0">
    <img
      src="assets/aboutUnique/14.png"
      className="w-100 shadow-1-strong rounded mb-4"
      alt="Mountains in the Clouds"
    />

    <img
      src="assets/aboutUnique/13.png"
      className="w-100 shadow-1-strong rounded mb-4"
      alt="Boat on Calm Water"
    />
  </div>

  <div className="col-lg-4 col-md-4 col-sm-4 mb-4 mb-lg-0">

        <img
      src="assets/aboutUnique/12.png"
      className="w-100 shadow-1-strong rounded mb-4"
      alt="Waves at Sea"
    />

    <img
      src="assets/aboutUnique/15.png"
      className="w-100 shadow-1-strong rounded mb-4"
      alt="Yosemite National Park"
    />
  </div>
</div>
        </div>
        <div className="col-lg-6">
          <h6 className="section-title text-start text-primary">
            About Us
          </h6>
          <h1 className="mb-4 text-primary">
          We extend a warm {""}
            <span className="" style={{color:"#006600"}}>
              {/* {about && about.resort ? about.resort : ""} */}
              welcome to our Resort
            </span>
          </h1>
          <div
            className="mb-4"
            dangerouslySetInnerHTML={{
              __html: `${
                about && about.description
                  ? about.resort_description.substr(0, 245)+"...."
                  : ""
              }`,
            }}
          ></div>
          <div className="row g-3 pb-4">
            {about && about.rooms ? (
              <div className="col-sm-4 wow fadeIn" data-wow-delay="0.1s">
                <div className="border rounded p-1">
                  <div className="border rounded text-center p-4">
                    <i className="fa fa-hotel fa-2x text-primary mb-2"></i>
                    <h2 className="mb-1" data-toggle="counter-up">
                      {about.rooms}
                    </h2>
                    <p className="mb-0">Rooms</p>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {about && about.staff ? (
              <div className="col-sm-4 wow fadeIn" data-wow-delay="0.3s">
                <div className="border rounded p-1">
                  <div className="border rounded text-center p-4">
                    <i className="fa fa-users-cog fa-2x text-primary mb-2"></i>
                    <h2 className="mb-1" data-toggle="counter-up">
                      {about.staff}
                    </h2>
                    <p className="mb-0">Staffs</p>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}

            {about && about.clients ? (
              <div className="col-sm-4 wow fadeIn" data-wow-delay="0.5s">
                <div className="border rounded p-1">
                  <div className="border rounded text-center p-4">
                    <i className="fa fa-users fa-2x text-primary mb-2"></i>
                    <h2 className="mb-1" data-toggle="counter-up">
                      {about.clients}
                    </h2>
                    <p className="mb-0">Clients</p>
                  </div>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <a className="theme-btn btn-style-two" href="/about">
          Discover further information
          </a>
        </div>
     
      </div>
    </div>
  </div>
  )
}

export default AboutUniqueWild