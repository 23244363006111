import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import PackageBooking from "../../booking-request/PackageBooking";
import { ToastContainer } from "react-toastify";
import "./PackagesList.css"

const PackagesList = () => {
  const { special_packages, popular_packages, imgUrl } = useSelector((state) => state.packages);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);
    
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };


  let packageList =
    popular_packages &&
    popular_packages.map((packageData, idx) => (
      <div
        className="col-lg-4 col-md-6 wow fadeInUp"
        data-wow-delay={`0.${idx}s`}
        key={idx}
      >
        <div className={`room-item shadow rounded overflow-hidden h-100 ${isModalOpen ? '' : 'costumehover'
          }`}>
          <div className="position-relative">
            {packageData && packageData.image ? (
              <img
                className="img-fluid"
                src={`${imgUrl}/${packageData.image}`}
                alt=""
              />
            ) : (
              ""
            )}
            <small className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4">
              Rs.{packageData.price}
            </small>
          </div>
          <div className="p-4 mt-2">
          <div className="d-flex justify-content-between align-items-center mb-3">
      <div className="title-container">
        <h5 className="mb-0 main-head">{packageData.title}</h5>
        <div className="sub-heading">
        <p className="mb-0  text-right ">{packageData.days}</p>

        </div>
      </div>
    </div>

            <p className="text-body mb-3 pt-3">
              {packageData.short_describe.substr(0, 110) + " ..."}
            </p>
            <div className="d-flex justify-content-between">
              <Link
                className="btn btn-sm btn-primary rounded "
                to={`/package/${packageData.slug}`}
                state={{ id: packageData.id }}
              >
                View Detail
              </Link>
              <button 
              className="btn btn-sm btn-dark rounded "
              data-bs-toggle="modal"
              data-bs-target={`#model${packageData?.id}`} // Assuming you want to access the room's ID
              onClick={openModal}
              >
                Book Now
              </button>
              {/* Modal Form */}
              <div className="modal fade" id={`model${packageData.id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <PackageBooking packageType={packageData.title}  closeModal={closeModal}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    ));


    
  let popularPackageList =
  special_packages &&
  special_packages.map((packageData, idx) => (
    <div
      className="col-lg-4 col-md-6 wow fadeInUp"
      data-wow-delay={`0.${idx}s`}
      key={idx}
    >
    
      <div className={`room-item shadow rounded overflow-hidden h-100 ${isModalOpen ? '' : 'costumehover'
        }`}>
        <div className="position-relative">
          {packageData && packageData.image ? (
            <img
              className="img-fluid"
              src={`${imgUrl}/${packageData.image}`}
              alt=""
            />
          ) : (
            ""
          )}
          <small className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4">
            Rs.{packageData.price}
          </small>
        </div>
        <div className="p-4 mt-2">
        <div className="d-flex justify-content-between align-items-center mb-3">
    <div className="title-container">
      <h5 className="mb-0 main-head">{packageData.title}</h5>
      <div className="sub-heading">
      <p className="mb-0  text-right ">{packageData.days}</p>

      </div>
    </div>
  </div>

          <p className="text-body mb-3 pt-3">
            {packageData.short_describe.substr(0, 110) + " ..."}
          </p>
          <div className="d-flex justify-content-between">
            <Link
              className="btn btn-sm btn-primary rounded"
              to={`/package/${packageData.slug}`}
              state={{ id: packageData.id }}
            >
              View Detail
            </Link>
            <button 
            className="btn btn-sm btn-dark rounded"
            data-bs-toggle="modal"
            data-bs-target={`#model${packageData?.id}`} // Assuming you want to access the room's ID
            onClick={openModal}
            >
              Book Now
            </button>
            {/* Modal Form */}
            <div className="modal fade" id={`model${packageData.id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <PackageBooking packageType={packageData.title}  closeModal={closeModal}/>
            </div>
          </div>
        </div>
      </div>
    </div>
  ));

  return (
    <div className="container-xxl py-5">
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          {/* <h1 className="section-title text-center text-primary">
            Our Packages List
          </h1> */}
          <h1 className="mb-2">
          Our  <span className="text-primary">Packages</span>
          </h1>
        </div>
        <div className="row g-4">{packageList}</div>
      </div>


      <div className="container my-5">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          <h4 className="section-title text-center text-primary mb-5">
          Excitement rises high in the wilderness. Join us for the adventure of lifetime.
          </h4>
          {/* <h1 className="mb-2">
          Our  <span className="text-primary">Packages</span>
          </h1> */}
        </div>
        <div className="row g-4">{popularPackageList}</div>
      </div>
      
    </div>
  );
};

export default PackagesList;
