import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

import RoomBookingContainer from "../../../booking-request/RoomBookingContainer";


const Rooms = () => {
  const { rooms } = useSelector((state) => state.rooms);
  const [roomType, setRoomType] = useState(null);

  const [isModalOpen, setIsModalOpen] = useState(false);
  const openModal = () => {
    setIsModalOpen(true);

  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleClick = (roomTitle, trigger) => {
    setRoomType(roomTitle);
    openModal();
  }


  let roomsData =
    rooms &&
    rooms.data &&
    rooms.data.slice(0, 3).map((room, idx) => (
      <div
        className="col-lg-4 col-md-6 wow fadeInUp"
        data-wow-delay={`0.${idx}s`}
        key={idx}
      >
        <div className={`room-item shadow rounded overflow-hidden h-100 ${isModalOpen ? '' : 'costumehover'
          }`}>
          <div className="position-relative">
            {room && room.image ? (
              <img
                className="img-fluid"
                src={`${room.image_url}/${room.image}`}
                alt=""
              />
            ) : (
              ""
            )}
            <small className="position-absolute start-0 top-100 translate-middle-y bg-primary text-white rounded py-1 px-3 ms-4">
              Rs.{room.price}/Night
            </small>
          </div>
          <div className="p-4 mt-2">
            <div className="d-flex justify-content-between mb-3">
              <h5 className="mb-0">{room.title}</h5>
            </div>
            <div className="d-flex mb-3">
              <small className="border-end me-3 pe-3">
                <i className="fa fa-bed text-primary me-2"></i>
                {room.bed} Bed
              </small>
              <small className="border-end me-3 pe-3">
                <i className="fa fa-bath text-primary me-2"></i>
                {room.bathroom} Bath
              </small>
              <small>
                <i className="fa fa-wifi text-primary me-2"></i>Wifi
              </small>
            </div>
            <p className="text-body mb-3">
              {room.short_description.substr(0, 110) + " ..."}
            </p>
            <div className="d-flex justify-content-between">
              <Link
                className="btn btn-sm btn-primary rounded py-2 px-4"
                to={`/room/${room.slug}`}
                state={{ id: room.id }}
              >
                View Detail
              </Link>
              <button
                type="button"
                className="btn btn-sm btn-dark rounded"
                data-bs-toggle="modal"
                data-bs-target={`#model${room?.id}`} // Assuming you want to access the room's ID
                onClick={()=>handleClick(room.title, true)}
              >
                Book Now
              </button>
              {/* Modal Form */}
              <div className="modal fade" id={`model${room.id}`} tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <RoomBookingContainer roomType={roomType} closeModal={closeModal}/>
              </div>
              
            </div>
          </div>
        </div>
      </div>
    ));

  return (
    <div className="container-xxl ">
      <div className="container">
        <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
          {/* <h6 className="section-title text-center text-primary">
            Our Rooms
          </h6> */}
          <h1 className="mb-5">
          Our{" "}
            <span className="text-primary">Accommodation</span>
          </h1>
        </div>
        <div className="row g-4">
          {roomsData}
        </div>
      </div>
    </div>
  );
};

export default Rooms;
