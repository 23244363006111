import React from "react";
import About from "./home-components/About";
import HomeBanners from "./home-components/HomeBanners";
import Rooms from "./home-components/Rooms";
import Service from "./home-components/Service";
import Teams from "./home-components/Teams";
import Video from "./home-components/Video";
// import BookingSite from "./home-components/BookingSite";
import AboutUniqueWild from "./home-components/AboutUniqueWild";
import Packages from "./home-components/Packages";
import PaymentOptiom from "./home-components/PaymentOptiom";

const HomePage = () => {
  
  return (
    <>
      <HomeBanners />
      <About />
      <AboutUniqueWild/>
      <Rooms />
      <Packages/>
      <Video />
      <Service />
      <PaymentOptiom/>
    </>
  );
};

export default HomePage;
