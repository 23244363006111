import React from "react";
import { useSelector } from "react-redux";
import ContactForm from "./ContactForm";

const Contact = () => {
  const { setting } = useSelector((state) => state.setting);

  return (
    <div className="container-xxl py-5">
      <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
        <h6 className="section-title text-center text-primary">
          Contact Us
        </h6>
        <h1 className="mb-3">
          <span className="text-primary">Contact</span> For Any
          Query
        </h1>
      </div>
      
      <div className="row">
        <div className="col-md-12">
            <h4>
              Kathmandu Sales Office
            </h4>
        </div>

        <div className="col-md-3">
            <hr style={{height:"3px", backgroundColor:"green" }}/>
          <div className="row">
            <div className="col-md-12">
              <p className="mb-2">
              <i className="fa fa-map-marker-alt me-3 text-primary"></i>
              <a href="/contact">Thamel, Kathmandu, Nepal</a>
            </p>
            <p className="mb-2">
              <i className="fa fa-phone-alt me-3 text-primary"></i>
              <a href={`tel:014258087`}>+977-01-4258087</a>
            </p>
            <p className="mb-2">
              <i className="fa fa-mobile-alt me-3 text-primary"></i>
              <a href={`tel:9851055105`}>+977-9851055105,+977-9863460819</a>
            </p>
            <p className="mb-2">
            <i className="fa fa-mobile-alt me-3 text-primary"></i>
            <a href={`tel:9855016105`}>+977-9855016105</a>
            </p>
            <p className="mb-2">
              <i className="fa fa-box me-3 text-primary"></i>
              <a href="">P.O. Box: 13818</a>
            </p>
            

            {/* <p className="mb-2">
              <i className="fa fa-fax me-3 text-primary"></i>
              <a href="">Fax: 4258573</a>
            </p> */}
            <p className="mb-2">
              <i className="fa fa-envelope me-3 text-primary"></i>
              <a href={`mailto:sales@uniquewildresort.com`}>
               sales@uniquewildresort.com
              </a>
            </p>
            </div>
          </div>
        </div>

        <div className="col-md-3">
          <h5>Resort</h5>
          <div className="row">
            <p className="mb-2">
              <i className="fa fa-map-marker-alt me-3 text-primary"></i>
              <a href="/contact">
                {setting && setting.address ? setting.address : ""}
              </a>
            </p>
            <p className="mb-2">
              <i className="fa fa-phone-alt me-3 text-primary"></i>
              <a href={`tel:${setting.phone}`}>
                +977-056-{setting && setting.phone ? setting.phone : ""}
              </a>
            </p>
            <p className="mb-2">
              <i className="fa fa-envelope me-3 text-primary"></i>
              <a href={`mailto:info@abc.com`}>
                {setting && setting.email ? setting.email : ""}
              </a>
            </p>
            </div>
        </div>
        <div className="col-md-6">
          <h5> Reservation Overseas</h5>
          <div className="row mb-3">
          <div className="col-md-6">
            <h6 className="section-title text-start text-primary mb-4">
              Hans Boehm
            </h6>
            <p className="mb-2">
              <i className="fa fa-map-marker-alt me-3 text-primary"></i>
              <a href="/contact">AM SEE 29, D 85540, HAAR GERMANY</a>
            </p>
            <p className="mb-2">
              <i className="fa fa-phone-alt me-3 text-primary"></i>
              <a href={`tel:+4989560535`}>+49-89-560535</a>
            </p>
            <p className="mb-2">
              <i className="fa fa-mobile-alt me-3 text-primary"></i>
              <a href={`tel:1718276080`}> +49-1718276080</a>
            </p>
            <p className="mb-2">
              <i className="fa fa-envelope me-3 text-primary"></i>
              <a href={`mailto:info@abc.com`}>h-jbm@freenet.de</a>
            </p>

         
          </div>
          <div className="col-md-6">
            <h6 className="section-title text-start text-primary mb-4">
              Raj/Christa Thapa
            </h6>
            <p className="mb-2">
              <i className="fa fa-map-marker-alt me-3 text-primary"></i>
              <a href="/contact">Hauptstr-4,D-74081, Heilbronn, Sontheim, Germany</a>
            </p>
            <p className="mb-2">
              <i className="fa fa-phone-alt me-3 text-primary"></i>
              <a href={`tel:014258087`}>
                07131 578779
              </a>
            </p>
            <p className="mb-2">
              <i className="fa fa-mobile-alt me-3 text-primary"></i>
              <a href={`tel:1796892408`}>+49-1796892408 , +49-1777241266</a>
            </p>
            <p className="mb-2">
              <i className="fa fa-envelope me-3 text-primary"></i>
              <a href={`mailto:info@abc.com`}>c.thapa@gmx.de</a>
            </p>

    
          </div>
        </div>
          </div>
      </div>

      <ContactForm setting={setting} />
    </div>
  );
};

export default Contact;
