import RoomBooking from "./RoomBooking";
import { ToastContainer } from "react-toastify";

const RoomBookingContainer = ({ roomType, closeModal }) => {
    return (
      <>
        <RoomBooking roomType={roomType} closeModal={closeModal }/>
        <ToastContainer />
      </>
    );
  };
  
  export default RoomBookingContainer;