import axios from "axios";
import { linkurl } from "../linkurl";

const API_URL = linkurl;

// Get All Products
const rooms = async () => {
  const res = await axios.get(`${API_URL}/rooms`);
  if (res.status === 200) {
    return res.data;
  }
};

// Get Room By Id
const room = async (id) => {
  const res = await axios.get(`${API_URL}/rooms/${id}`);
  if (res.status === 200) {
    return res.data;
  }
};

const roomService = {
  rooms,
  room,
};
export default roomService;
